<template>
  <div>
    <div class="row mt-4 mb-4">
        <h1 class="col-sm-12 text-right">Configuración</h1>
    </div>    
    <DataGrid :configuracion="datagrid_config" :data="configuraciones" @ready="buscar_botones">
        <div class="row">
            <div class="col-sm-12">
                <button class="btn btn-secondary mr-2" @click="obtener_configuracion">Recargar</button>
                <button class="btn btn-success" @click="modal_crear_configuracion=true">Nueva Configuración</button>
            </div>
        </div>
    </DataGrid>
    
    <Nueva v-if="modal_crear_configuracion" @close="cerrar_modal_nueva_config" :editar_configuracion="configuracion" @update="obtener_configuracion"/>
    <Eliminar v-if="modal_eliminar_configuracion" @close="cerrar_modal_eliminar_config" :configuracion="configuracion" @update="obtener_configuracion"/>

  </div>
</template>

<script>

import DataGrid from '@/components/DataGridV3';
import apiClientes from '@/apps/clientes/api/clientes';
import Nueva from "./Configuracion/Nueva";
import Eliminar from "./Configuracion/Eliminar";
export default {

    components:{
        DataGrid, Nueva, Eliminar
    },
    data(){
        return{
            datagrid_config: {
                name: 'configuracion',
                selector: false,
                cols: {
                    clave: 'Clave',
                    descripcion: 'Descripción',
                    estatus: 'Estatus',
                    opciones:{
                        text: 'Opciones',
                        ordeable: false                        
                    },
                },
                mutators: {
                    estatus(val) {
                    return val == 1 ? 'Activo' : 'Inactivo';
                    },
                    opciones: function(val, row, vue){
                        return `
                            <img class="mr-2 btn-opciones" data-action="editar" data-config="${vue.$helper.toJson(row)}" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar configuracion" title="Editar configuracion" />
                            <img class="btn-opciones" data-action="eliminar" data-config="${vue.$helper.toJson(row)}" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar configuracion" title="Elimitar configuracion" />
                        `;
                    }
                }
            },
            configuraciones:[],
            modal_crear_configuracion:false,
            configuracion:null,
            modal_eliminar_configuracion:false,
        }
    },
    methods:{
        async obtener_configuracion(){
            try {
                this.configuraciones =  (await apiClientes.listar_configuracion()).data;
                this.$log.info('configuraciones', this.configuraciones);
                
            } catch (e) {
                this.$log.info('err', e)
                this.$helper.showAxiosError(e,'Error');
            }

        },
        buscar_botones(){
        let botones = document.querySelectorAll('.btn-opciones');

        botones.forEach(btn => {
            btn.removeEventListener('click', (e) => {this.realizar_accion(e)}, false);
            btn.addEventListener('click', (e) =>{this.realizar_accion(e)}, false);
        });            
        },
        realizar_accion(e){
            this.configuracion =  this.$helper.fromJson(e.target.dataset.config);
            let accion = e.target.dataset.action;

            if(accion == 'editar')
                this.modal_crear_configuracion = true;
            else
                this.modal_eliminar_configuracion = true;
        },
        cerrar_modal_nueva_config(){
            this.modal_crear_configuracion = false;
            this.configuracion = null;
        },
        cerrar_modal_eliminar_config(){
            this.modal_eliminar_configuracion = false;
            this.configuracion = null;
        }
    },
    mounted(){
        this.obtener_configuracion();
    }
}

</script>

<style lang="scss">
  table {
    tbody {
      tr {
        td {
          img {
            width: 20px;
          }
        }
      }
    }
  }
</style>