<template>
  <div>
    <Modal :options="{width: '40vw',type: 'danger'}" @close="$emit('close')">
      <div class="title">Eliminar configuracion</div>
      <div class="body">
        <p class="text-center">Estas por eliminar la configuración <strong>{{ configuracion.clave }}</strong>, esta acción no se puede revertir.<br/><br/>¿Deseas continuar?</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="eliminar">Eliminar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

import apiClientes from '@/apps/clientes/api/clientes';

  export default {
    components: {
      Modal
    }
    ,props: {
      configuracion: {
        type: Object
        ,default() {
          return {
            id: null
            ,clave: null
          }
        }
      }
    }
    ,methods: {
      async eliminar() {
        try {
          await apiClientes.eliminar_configuracion(this.configuracion.id);
          this.$emit('update');
          this.$emit('close');

        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>